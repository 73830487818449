import {Component, EventEmitter, forwardRef, HostBinding, Input, OnInit, Output} from '@angular/core';
import {debounceTime, distinctUntilChanged, map, switchMap, takeUntil} from 'rxjs/operators';
import {forkJoin, Observable, of} from 'rxjs';
import {APIService, DrugCategorySerializer, DrugNameSerializer, ICDCodeSerializer, PatientSerializer, PhysicianSerializer} from '../../../../@core/api.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ForwardNgSelectRefs} from '../advanced-input/forward-ng-select-refs';
import {toIcdFormat} from '../../../../utils/string.utils';
import {compareByIds} from '../../../../utils/select-option.utils';

type ComboInputSearchType = 'multi' | 'single' | null;
type ComboInputTypeKey = 'physician' | 'patient' | 'drugCategory' | 'drugName' | 'icdCode';

export interface ComboInputValue {
    selectType: {type: string; icon: string};
    id: number;
    name: string;
}

@Component({
    selector: 'app-combo-input',
    templateUrl: './combo-input.component.html',
    styleUrls: ['./combo-input.component.scss'],
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ComboInputComponent), multi: true},
    ],
})
export class ComboInputComponent extends ForwardNgSelectRefs implements ControlValueAccessor, OnInit {
    @Input() patientSearch: ComboInputSearchType;
    @Input() physicianSearch: ComboInputSearchType;
    @Input() drugNameSearch: ComboInputSearchType;
    @Input() drugCategorySearch: ComboInputSearchType;
    @Input() icdCodeSearch: ComboInputSearchType;

    @Input() showLabelIcon = true;
    @Input() placeholder: string;
    @Input() required = false;
    @Input() disabled = false;
    @Input() bindLabel = 'name';
    @Input() numberOfResults = 10;
    @Input() modelOptions: {
        name?: string;
        standalone?: boolean;
        updateOn?: string;
    };
    @HostBinding('class.is-invalid')
    @Input() invalid: boolean;
    @Input() error: string;

    @Output() change = new EventEmitter(); // for emitting original data of parsed options

    compareByIds = compareByIds;
    selectItems: any[];
    model: ComboInputValue[];
    typeahead = new EventEmitter<string>();
    selectTypes = {
        patient: {
            type: 'patient',
            icon: 'user',
        },
        physician: {
            type: 'physician',
            icon: 'user-md',
        },
        drugName: {
            type: 'drug_name',
            icon: 'pills',
        },
        drugCategory: {
            type: 'drug_category',
            icon: 'prescription-bottle',
        },
        icdCode: {
            type: 'icd',
            icon: null,
        },
    };

    _prevModel: ComboInputValue[];
    _apiCalls: ((params) => Observable<any>)[] = [];

    constructor(private api: APIService) {
        super();
    }

    ngOnInit() {
        this._apiCalls = this._getApiCalls('list');

        this.typeahead.pipe(
            takeUntil(this.destroy$),
            distinctUntilChanged(),
            debounceTime(200),
            switchMap(term => term ? this.loadOptions(term) : of(null))
        ).subscribe(opts => this.selectItems = opts);
    }

    loadOptions(searchTerm: string): Observable<any> {
        this.cancelRequest$.next();

        const requestParams: any = {search: searchTerm};
        if (this.numberOfResults) {
            Object.assign(requestParams, {page_size: this.numberOfResults, page: 1});
        }

        return forkJoin(
            this._apiCalls
                .filter(obs => !!obs)
                .map(obs => obs(requestParams))
        ).pipe(takeUntil(this.cancelRequest$), map(res => {
            let results = [];

            for (const result of res) {
                results = results.concat(result['results'] || result);
            }

            return results.map(result => ({
                ...result,
                relevancy: this._calculateOptionRelevancy(searchTerm, result),
            })).sort((a, b) => b.relevancy - a.relevancy);
        }));
    }

    writeValue(value: ComboInputValue[]) {
        // TODO this needs to handle loading the multi select items too
        if (value !== undefined) {
            let isChanged = false;
            const temp = [];
            if (value) {
                if (Array.isArray(value)) {
                    value = value.map(val => {
                        const out = this._getValue(val);
                        temp.push(out);
                        if (out != val) {
                            isChanged = true;
                        }
                        return out;
                    });
                } else {
                    const out = this._getValue(value);
                    temp.push(out);
                    if (out != value) {
                        isChanged = true;
                        value = out;
                    }
                }

                this.model = value;
                this._prevModel = value;

                if (isChanged) setTimeout(() => this.propagateChange(value));

                const va = (Array.isArray(value) ? value : [value]);
                va.forEach(val => {
                    if (!this.selectItems || this.selectItems.every(x => this._getValue(x) != val)) {
                        const res = temp.find(x => this._getValue(x) == val);
                        const id = val.id;
                        const type = val.selectType.type;
                        (res?.label && res.selectType ? of(res) : this._getApiCalls('retrieve', type)(id)).subscribe(res => {
                            if (!res) return;
                            const setVal = va.find(x => x.id === res.id);
                            if (setVal) Object.assign(setVal, res);

                            this.selectItems = [...(this.selectItems || []), (setVal || res)];
                        });
                    }
                });
            }

            this.model = value;
            this._prevModel = value;
        }
    }

    onNgModelChange(value: ComboInputValue[]) {
        this.model = this._processValue(value);
        if ((!this.modelOptions || !['blur', 'submit'].includes(this.modelOptions.updateOn)) && this._prevModel != this.model) this._processChange();
    }

    stopSelectPropagation(event) {
        if (event.composedPath().some(el => el.tagName === 'NG-DROPDOWN-PANEL')) event.stopPropagation();
    }

    propagateChange = (_: any) => {
    };

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn) {

    }

    private _getApiCalls(callMethod: 'list' | 'retrieve'): ((x) => Observable<any>)[];
    private _getApiCalls(callMethod: 'list' | 'retrieve', callType: string): (x) => Observable<any>;
    private _getApiCalls(callMethod: 'list' | 'retrieve', callType?: string): ((x) => Observable<any>) | ((x) => Observable<any>)[] {
        const calls: {[key in ComboInputTypeKey]: (params) => Observable<any>} = {
            patient: null,
            physician: null,
            drugName: null,
            drugCategory: null,
            icdCode: null,
        };

        if (callMethod === 'list') {
            if (this.patientSearch) {
                calls.patient = params =>
                    this.api
                        .PatientViewSet
                        .list(params)
                        .pipe(
                            map(res => res.results.map(patient => this._createPatientOption(patient)))
                        );
            }
            if (this.physicianSearch) {
                calls.physician = params =>
                    this.api
                        .PhysicianViewSet
                        .list(params)
                        .pipe(
                            map(physicians => physicians.map(physician => this._createPhysicianOption(physician)))
                        );
            }
            if (this.drugNameSearch) {
                calls.drugName = params =>
                    this.api
                        .DrugNameViewSet
                        .list({...params, has_treatment: true})
                        .pipe(
                            map(drugNames => drugNames.results.map(drugName => this._createDrugNameOption(drugName)))
                        );
            }
            if (this.drugCategorySearch) {
                calls.drugCategory = params =>
                    this.api
                        .DrugCategoryViewSet
                        .list(params)
                        .pipe(
                            map(drugCategories => drugCategories.results.map(drugCategory => this._createDrugCategoryOption(drugCategory)))
                        );
            }
            if (this.icdCodeSearch) {
                calls.icdCode = params =>
                    this.api
                        .ICDCodeViewSet
                        .list(this._icdParamsTransformer(params))
                        .pipe(
                            map(icdCodes => icdCodes.results.map(icdCode => this._createIcdCodeOption(icdCode)))
                        );
            }
        } else {
            if (this.patientSearch) {
                calls.patient = params =>
                    this.api
                        .PatientViewSet
                        .retrieve(params)
                        .pipe(
                            map(res => this._createPatientOption(res))
                        );
            }
            if (this.physicianSearch) {
                calls.physician = params =>
                    this.api
                        .PhysicianViewSet
                        .retrieve(params)
                        .pipe(
                            map(physician => this._createPhysicianOption(physician))
                        );
            }
            if (this.drugNameSearch) {
                calls.drugName = params =>
                    this.api
                        .DrugNameViewSet
                        .retrieve({...params, has_treatment: true})
                        .pipe(
                            map(drugName => this._createDrugNameOption(drugName))
                        );
            }
            if (this.drugCategorySearch) {
                calls.drugCategory = params =>
                    this.api
                        .DrugCategoryViewSet
                        .retrieve(params)
                        .pipe(
                            map(drugCategory => this._createDrugCategoryOption(drugCategory))
                        );
            }
            if (this.icdCodeSearch) {
                calls.icdCode = params =>
                    this.api
                        .ICDCodeViewSet
                        .retrieve_detail(this._icdParamsTransformer(params))
                        .pipe(
                            map(icdCode => this._createIcdCodeOption(icdCode))
                        );
            }
        }

        switch (callType) {
            case 'patient':
                return calls.patient;
            case 'physician':
                return calls.physician;
            case 'drug_name':
                return calls.drugName;
            case 'drug_category':
                return calls.drugCategory;
            case 'icd':
                return calls.icdCode;
            default:
                return Object.values(calls);
        }
    }

    private _getValue(item: any) {
        if (typeof item === 'number' || !item) return item;

        const selectItem = item.id && this.selectItems && this.selectItems.length && this.selectItems.find(x => x.id == item.id);

        return selectItem || item;
    }

    private _processChange() {
        this.invalid = false;
        this.error = null;
        this._prevModel = this.model;
        this.propagateChange(this.model);

        if (this.selectItems) {
            const t = this.selectItems.find(x => this._getValue(x) == this.model);
            if (t) this.change.emit(t);
        }
    }

    private _icdParamsTransformer(params) {
        if (!params.search) return params;

        return {
            ...params,
            search: params.search
                .split('')
                .filter(letter => letter !== '.')
                .join(''),
        };
    }

    private _createPatientOption(patient: PatientSerializer) {
        return {
            ...patient,
            label: `${patient.first_name} ${patient.last_name}`,
            secondaryLabel: patient.mrid,
            id: patient.id,
            selectType: this.selectTypes.patient,
        };
    }

    private _createPhysicianOption(physician: PhysicianSerializer) {
        return {
            ...physician,
            label: `${physician.first_name} ${physician.last_name}`,
            secondaryLabel: physician.email,
            selectType: this.selectTypes.physician,
        };
    }

    private _createDrugNameOption(drugName: DrugNameSerializer) {
        return {
            ...drugName,
            label: drugName.name,
            selectType: this.selectTypes.drugName,
        };
    }

    private _createDrugCategoryOption(drugCategory: DrugCategorySerializer) {
        return {
            ...drugCategory,
            label: drugCategory.name,
            selectType: this.selectTypes.drugCategory,
        };
    }

    private _createIcdCodeOption(icd: ICDCodeSerializer) {
        return {
            ...icd,
            label: icd.description,
            secondaryLabel: toIcdFormat(icd.display_name),
            selectType: this.selectTypes.icdCode,
        };
    }

    private _calculateOptionRelevancy(term: string, {label, secondaryLabel}: {label: string; secondaryLabel?: string}): number {
        return this._calculateRelevancyForLabel(term, label) + this._calculateRelevancyForLabel(term, secondaryLabel);
    }

    private _calculateRelevancyForLabel(term: string, label: string): number {
        if (!term || !label) return 0;

        const termWords = term.toLowerCase().split(' ');
        const labelWords = label.toLowerCase().split(' ');

        return termWords.reduce((score, termWord) => score - labelWords.reduce((tws, w) => {
            const i = w.indexOf(termWord);
            return i > -1 && i < tws ? i : tws;
        }, 100), 100);
    }

    private _getSearchTypeByKey(searchType): ComboInputSearchType {
        switch (searchType) {
            case 'patient':
                return this.patientSearch;
            case 'physician':
                return this.physicianSearch;
            case 'drug_name':
                return this.drugNameSearch;
            case 'drug_category':
                return this.drugCategorySearch;
            case 'icd':
                return this.icdCodeSearch;
            default:
                return null;
        }
    }

    private _processValue(model: ComboInputValue[]) {
        if (Array.isArray(model)) {
            const prev = this._prevModel;
            if (!prev?.length) return model;
            return model.filter(m =>
                this._getSearchTypeByKey(m.selectType.type) === 'multi' ||
                model.filter(x => x.selectType.type === m.selectType.type).length < 2 ||
                prev.every(x => x.id !== m.id || x.selectType.type !== m.selectType.type)
            );
        }
        return model;
    }
}
