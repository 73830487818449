<ng-select (click)="stopSelectPropagation($event)"
           (ngModelChange)="onNgModelChange($event)"
           [ngModel]="model"
           [items]="selectItems"
           [multiple]="true"
           [typeahead]="typeahead"
           [bindLabel]="bindLabel"
           [placeholder]="placeholder"
           [required]="required"
           [disabled]="disabled"
           [compareWith]="compareByIds"
           class="purified">
    <ng-template ng-option-tmp let-item="item">
        <div class="d-flex align-items-center">
            <far *ngIf="item.selectType.icon" [i]="item.selectType.icon" class="text-primary mr-2"></far>
            <span class="mr-auto">{{item.label}}</span>
            <small *ngIf="item.secondaryLabel" class="ml-2 text-muted">{{item.secondaryLabel}}</small>
        </div>
    </ng-template>
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span class="ng-value-label">
            <span class="d-flex align-items-center">
                <far *ngIf="showLabelIcon && item.selectType.icon" [i]="item.selectType.icon" class="text-primary mr-2"></far>
                <span class="mr-auto">{{item.label}}</span>
                <small *ngIf="item.secondaryLabel" class="ml-2 text-muted">{{item.secondaryLabel}}</small>
            </span>
        </span>
        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
    </ng-template>
</ng-select>
